.block-portada {
    --padding-top: var(--padding-xxs);
    --padding-bottom: var(--padding-xxs);
    --padding-h: var(--padding-xxs);

    @media (max-width: $smartphone) {
        --padding-top: 0;
        --padding-bottom: var(--padding-l);
        --padding-h: var(--padding-small);
    }
}

.block-portada {
    @include font-sans();
    @include z-index($z-index-cookies);

    color: var(--white);
    font-size: var(--font-size-base);
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    position: relative;

    @media (max-width: $smartphone) {
        .text {
            margin-top: var(--padding-l);
        }
    }

    @media (min-width: $smartphone) {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

        .text {
            bottom: var(--padding-bottom);
            position: absolute;
            left: 29vw;
        }
    }
}

.back {
    @include z-index($z-index-progress);

    --padding-top: var(--padding-xxs);
    --padding-bottom: var(--padding-xxs);
    --padding-h: var(--padding-xxs);

    @media (max-width: $smartphone) {
        --padding-top: 0;
        --padding-bottom: var(--padding-ms);
        --padding-h: var(--padding-s);
    }

    position: fixed;
    overflow: hidden;

    button {
        @include basic-a();
        @include z-index($z-index-wrap);

        color: var(--white);
        background-color: transparent;
        text-align: right;
        position: position;
        padding: 0;
    }

     @media (max-width: $smartphone) {
        bottom: var(--padding-bottom);
        right: var(--padding-h);
    }

    @media (min-width: $smartphone) {
        bottom: var(--padding-bottom);
        right: var(--padding-h);
    }
}

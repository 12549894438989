.numbers {
    --height: #{330px / 16px * 1rem};

    @media (max-width: $smartphone) {
        --height: #{140px / 16px * 1rem};
    }
}

.numbers {
    @include font-sans();

    color: var(--white);
    font-size: var(--font-size-base);
    overflow: hidden;

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .progress {
        height: var(--height);
        margin: var(--padding-small) 0;
        position: relative;
        width: em(1);

        .track,
        .thumb {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform-origin: center bottom;
            transform: scale3d(1, 0, 1);
        }

        .track {
            background-color: var(--grey);
        }

        .thumb {
            background-color: var(--white);
        }
    }

    .number {
        overflow: hidden;

        span {
            display: inline-block;
        }
    }

    @media (min-width: $smartphone) {
        .progress {
            margin: var(--padding-s) 0;
        }
    }
}
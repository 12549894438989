:root {
    --font-sans: 'HelveticaNeueLTPro-Roman', sans-serif;
    --font-sans-thin: 'HelveticaNeueLTPro-Th', sans-serif;

    --font-size-xxlarge: #{(110px / 16px) * 1rem};
    --font-size-xlarge: #{(90px / 16px) * 1rem};
    --font-size-large: #{(45px / 16px) * 1rem};
    --font-size-mid: #{(36px / 16px) * 1rem};
    --font-size-base: #{(14px / 16px) * 1rem};
    --font-size-small: #{(12px / 16px) * 1rem};

    --line-height-sans: 1.428;

    font-size: 1.1111vw;

    @media (max-width: $smartphone) {
        --font-size-xxlarge: #{(60px / 16px) * 1rem};
        --font-size-xlarge: #{(46px / 16px) * 1rem};
        --font-size-large: #{(36px / 16px) * 1rem};
        --font-size-mid: #{(25px / 16px) * 1rem};

        font-size: 16px;
    }
}

@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 {
        line-height: $line-height;
    } @else {
        line-height: var(--line-height-sans);
    }
}

@mixin font-sans-thin($line-height: 0) {
    font-family: var(--font-sans-thin);
    font-weight: 200;
    letter-spacing: rem(-3);
    @if $line-height > 0 {
        line-height: $line-height;
    } @else {
        line-height: var(--line-height-sans);
    }
}

@mixin font-sans-italic($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    font-style: italic;
    @if $line-height > 0 {
        line-height: $line-height;
    } @else {
        line-height: var(--line-height-sans);
    }
}

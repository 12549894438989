#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--white);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-xs);
    --font-size: var(--font-size-base);
    --logo-width: #{133px / 16px * 1rem};

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-small);
        --padding-h: var(--padding-small);
        --logo-width: #{90px / 16px * 1rem};
    }

    &.--active {
        --color: var(--black);

        svg .target {
            fill: var(--color);
        }
    }
}

#Header {
    @include z-index($z-index-scrollbar);
    @include font-sans(1.5);

    align-items: flex-start;
    color: var(--color);
    display: flex;
    font-size: var(--font-size);
    height: var(--height);
    justify-content: space-between;
    padding: var(--padding-v) var(--padding-h);
    position: fixed;
    right: 0;
    top: 0;
    transform: translate3d(0, -100%, #{$z-index-header}px);
    width: var(--width);

    .gav {
        @include font-sans(1);

        display: flex;
        align-items: center;
        height: 100%;
    }

    .logo {
        @include basic-a();

        text-align: center;
        width: var(--logo-width);

        svg {
            height: auto;
            pointer-events: none;
            width: 100%;

            path {
                transition: .2s ease-out;
            }
        }

        &[aria-current="page"] {
            pointer-events: none;
        }
    }

    @media (max-width: $smartphone) {
        > div:first-child {
            display: none;
        }
    }
}

#info-toggle {
    a {
        color: currentColor;
    }

    background-color: transparent;
    text-align: right;
    text-transform: uppercase;
    padding: 0;

    a:last-child {
      display: none;
    }

    .--active & {
        background-size: 0 var(--stroke), 100% var(--stroke);

        a:first-child {
            display: none;
        }

        a:last-child {
            display: block;
        }
    }
}

#project-counter {
    @include z-index($z-index-3D);

    bottom: rem(140);
    position: fixed;
    right: var(--padding-small);

    .progress {
        transform-origin: center bottom;
    }

    .progress .track {
        transform: scale3d(1, 1, 1);
    }

    @media (max-width: $smartphone) {
        bottom: rem(120);
        right: var(--padding-xxs);
    }
}
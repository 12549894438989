#project-titles {
    @include z-index($z-index-3D);
    @include font-sans();
        
    color: var(--white);
    font-size: var(--font-size-base);
    height: 100%;
    left: 0;
    position: fixed;

    .title-wrapper {
        left: var(--padding-small);
        position: absolute;
    }

    @media (max-width: $smartphone) {
        .title-wrapper {
            bottom: rem(120);
        }
    }

}
    
.title-wrapper {
    .title {
        @include font-sans-thin(1.2);

        font-size: var(--font-size-xxlarge);
        position: relative;
        
        .lines-parent {
            margin-bottom: rem(-10);
        }
    }

    @media (max-width: $smartphone) {
        .title {
            margin: var(--padding-xs) 0;
        }
    }

    @media (min-width: $smartphone) {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: rem(430);
        top: 50%;
        left: var(--padding-xxs);
        transform: translate3d(0, -50%, #{$z-index-scrollbar}px);
        position: absolute;
        width: rem(500);
    }
}
//↗ → ← ↖
@mixin link-underline() {
    --stroke: max(1px, 0.08em); 

    @include basic-a();
    text-decoration: none;
    background: 
		linear-gradient(to right, transparent, transparent),
		linear-gradient(to right, currentColor, currentColor);
	background-size: 100% var(--stroke), 0 var(--stroke);
    background-position: 100% calc(100% - .07em), 0 calc(100% - .07em);
    background-repeat: no-repeat;
	transition: background-size .4s;
    
    &:hover {
	    background-size: 0 var(--stroke), 100% var(--stroke);
    }
    
    &.--active,
    &.__link-active {
        background-size: 0 var(--stroke), 100% var(--stroke);
        pointer-events: none;
    }

    /// TOUCH
    @include isTouch() {
	    background-size: 0 var(--stroke), 100% var(--stroke);
    }
}

.link-underline {
    @include link-underline();
    @include font-sans(1.667);

    font-size: var(--font-size-small);
    text-transform: uppercase;
    letter-spacing: rem(2);
}

:root {
  --white: #f5f5f5;
  --whiteRGB: 245, 245, 245;
  --black: #1A1A1A;
  --grey: #999;

  --primary: var(--black);
  --secondary: var(--white);

  --red: #C55D4B;
  --brown: #EEF1E5;

  --blue: #4B658A;
  --light-blue: #E8F3F0;

  --assertive: #ff00ff;
  --focus: #ff00ff;
  --color-error: #ff0000;
  --color-sucess: #4dc247;
}

.palette-primary {
    --primary: var(--red);
    --secondary: var(--brown);
}

.palette-secondary {
    --primary: var(--blue);
    --secondary: var(--light-blue);
}

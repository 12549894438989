html {
    width: 100%;
    height: 100%;
    background: var(--secondary);
}

body {
    width: 100%;
    min-height: 100%;

    main {
        width: 100%;
    }

    &:not(.__scroll-manual) {
        overflow: hidden;

        main {
            position: fixed;
            @include z-index($z-index-wrap);
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    &.__scroll-manual {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }

    &.__mobile {
        overflow: hidden;

        #Main {
            .wrap {
                height: 100%;
                overflow-y: scroll;
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;
            }

            @media (min-width: $smartphone) {
                article {
                    min-height: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.lines-parent {
    overflow: hidden;
}

#awwwards {
    transform: translateY(-185%);
}

@media (max-width: $smartphone) {
    #awwwards {
        transform: scale3d(0.75, 0.75, 1) translate3d(13.27px, -200%, 0);
    }
}

:root {
  --y-header: 0;

  --padding-xxl: #{(150px / 16px) * 1rem};
  --padding-xl: #{(100px / 16px) * 1rem};
  --padding-large: #{(75px / 16px) * 1rem};
  --padding-l: #{(60px / 16px) * 1rem};
  --padding-m: #{(50px / 16px) * 1rem};
  --padding-ms: #{(40px / 16px) * 1rem};
  --padding-s: #{(30px / 16px) * 1rem};
  --padding-sm: #{(25px / 16px) * 1rem};
  --padding-small: #{(20px / 16px) * 1rem};
  --padding-xs: #{(16px / 16px) * 1rem};
  --padding-xxs: #{(10px / 16px) * 1rem};
  --padding-xxxs: #{(5px / 16px) * 1rem};

  --header-height: #{(72px / 16px) * 1rem};
  --arrow-size: #{(24px / 16px) * 1rem};
}

@media (max-width: $smartphone) {
    :root {
        --header-height: #{(46px / 16px) * 1rem};
        --padding-xxl: #{(96px / 16px) * 1rem};
    }
}

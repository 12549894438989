.block-images {
    --padding-top: 23vh;
    --padding-bottom: 23vh;
    --padding-h: 0;
    --item-margin: var(--padding-xxs);
    --height: 54vh;
    
    @media (max-width: $smartphone) {
        --padding-top: calc(var(--header-height) + var(--padding-xs));
        --padding-bottom: var(--padding-small);
        --padding-h: var(--padding-small);
        --height: #{350px / 16px * 1rem};
    }   
}

.block-images {
    @include z-index($z-index-wrap);

    overflow: hidden;
    position: relative;
    width: 100vw;

    &__wrapper {
        height: var(--height);
        position: relative;
    }

    &__holder {
        align-items: center;
        display: flex;
        height: 100%;
        flex-wrap: nowrap;
        justify-content: flex-start;
        width: auto;
        user-select: none;
        
        > * {
            padding-right: var(--padding-xxs);
        }
    }
    
    .first-image {
        margin-right: var(--padding-xxs);
        padding-right: 0;

        img {
            object-fit: cover;
        }
    }

    .media-holder {
        display: flex;
        height: 100%;
        padding: 0;
        width: auto;

        svg {
            height: var(--height);
            position: relative;
            width: auto;
        }
    }
    
    @media (max-width: $smartphone) {
        padding: var(--padding-top) 0 var(--padding-bottom);

        .spacer {
            display: none;
        }

        &__holder {
            overflow-y: hidden;
            overflow-x: scroll;
            padding: 0 var(--padding-h);
            scroll-snap-type: x mandatory;
            
            > :last-child {
                padding-right: 0
            }
        }
    }  

    @media (min-width: $smartphone) {
        margin: var(--padding-top) var(--padding-h) var(--padding-bottom);

        .spacer {
            height: 54vw;
            width: 29vw;

            > * {
                visibility: hidden;
            }
        }

        .first-image {
            img {
                opacity: 0;
            }
        }
    }   
}

.first-image {
    @media (max-width: $smartphone) {
        height: rem(350);
        width: 95%;
    }  

    @media (min-width: $smartphone) {
        height: 54vh;
        width: 42vw;
    }  
}  

#projects-canvas {
    @include z-index($z-index-bg);

    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}

.dg.ac {
    @include z-index($z-index-windows);
    z-index: $z-index-windows !important;
}

.block-timeline {
    height: 100%;
    left: 0;
    position: fixed;
    pointer-events: none;
    top: 0;
    width: 100%;

    @include z-index($z-index-sidemenu);

    .article {
        background-color: transparent;
        // border-top: 2px solid #ff0000;
        // color: #ff0000;
        height: 100vh;
        font-size: 100px;
        width: 100vw;
        visibility: hidden;

        img {
            height: auto;
            visibility: hidden;
            width: 250%;
        }
    }

    @media (min-width: $smartphone) {
        .article {
            img {
                width: 90%;
            }
        }
    }

    @media (min-width: $xlargeScreen) {
        .article {
            img {
                width: 75%;
            }
        }
    }
}

#first-image {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
}

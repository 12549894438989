.body-not-found {
    @include font-sans();

    color: var(--white);
    text-align: center;

    article {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100vh;
    }

    h1 {
        @include font-sans-thin(1.2);

        font-size: var(--font-size-xxlarge);
        margin: var(--padding-s) 0;
        position: relative;
    }

    a {
        color: var(--white);
    }

    @media (max-width: $smartphone) {
        article {
            text-align: left;
            padding: 0 var(--padding-small);
        }

        h1 {
            @include font-sans-thin(1);
        }
    }

    @media (min-width: $smartphone) {
        #Header {
            justify-content: center;
        }
    }
}
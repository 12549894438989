#scroll-down {
    --pos: #{220px / 16px * 1rem};

    @include font-sans(1);

    color: var(--white);
    font-size: var(--font-size-small);
    text-transform: uppercase;
    letter-spacing: rem(2);
    pointer-events: none;
    opacity: 0;

    align-items: center;
    display: flex;
    position: fixed;
    bottom: var(--pos);
    left: var(--padding-small);

    svg {
        height: rem(20);
        margin-left: rem(5);
        width: rem(20);
    }

    @media (min-width: $smartphone) {
        --pos: 49%;
        left: var(--padding-xxs);
    }
}
#projects-thumbnails {
    --padding-v: var(--padding-xxs);
    --padding-h: var(--padding-small);
    --margin: var(--padding-xxs);
    --height: #{64px / 16px * 1rem};

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxs);
        --padding-h: var(--padding-xxs);
        --margin: var(--padding-xxxs);
        --height: #{45px / 16px * 1rem};
    }
}

#projects-thumbnails {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    @include z-index($z-index-cookies);

    bottom: var(--padding-v);
    overflow: hidden;
    position: absolute;
    pointer-events: none;
    left: 0;
    width: 100vw;

    ul {
        align-items: stretch;
        display: flex;
        left: 0;
        position: absolute;
        padding: 0 var(--padding-h);
        bottom: 0;
        width: auto;

        li {
            cursor: pointer;
            height: var(--height);
            overflow: hidden;

            * { pointer-events: none; }

            .media-holder {
                padding: 0;
            }

            svg {
                height: var(--height);
                position: relative;
                width: auto;
            }
         
            img {
                height: var(--height);
                filter: grayscale(100%);
                transition: all .3 ease-out;
                opacity: .7;
            }

            &.hover {
                img {
                    filter: grayscale(0);
                    opacity: 1;
                }
            }

            &:not(:last-child) {
                margin-right: var(--margin);
            }
        }

        @include isCursor () {
            &.hover {
                img {
                    filter: grayscale(100%);
                }

                li:hover {
                    img {
                        filter: grayscale(0%);
                    }
                }
            }
        }
    }
}
#page-loader {
    --font-size: var(--font-size-xxlarge);
    --circle: #{(600px / 16px) * 1rem};
    --logo-width: #{(286px / 16px) * 1rem};
    --logo-height: #{(99px / 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --circle: #{(320px / 16px) * 1rem};
        --font-size: var(--font-size-xlarge);
        --logo-width: #{(200px / 16px) * 1rem};
        --logo-height: #{(70px / 16px) * 1rem};
    }

    @media (min-width: $largeScreen) {
        --circle: 600px;
        --font-size: 110px;
        --logo-width: 200px;
        --logo-height: 80px;
    }
}

#page-loader {
    @include font-sans-thin(1);
    @include z-index($z-index-loader);

    align-items: center;
    justify-content: center;
    background: var(--black);
    bottom: 0;
    color: var(--white);
    display: flex;
    flex-direction: column;
    font-size: var(--font-size);
    left: 0;
    position: fixed;
    pointer-events: none;
    right: 0;
    top: 0;

    opacity: 0;

    .logo {
        height: var(--logo-height);
        overflow: hidden;
        position: absolute;
        width: var(--logo-width);

        svg {
            height: 100%;
            width: 100%;
        }
    }

    .circle {
        position: absolute;

        svg {
            height: var(--circle);
            width: var(--circle);
        }
    }

    .text {
        overflow: hidden;
        margin-top: rem(20);
    }

    .number {
        align-items: flex-start;
        display: flex;
        justify-content: center;

        .sup {
            height: 50%;
        }

        .sup,
        .progress {
            overflow: hidden;
            min-width: rem(30);
            position: relative;
        }
    }

    .__text,
    .__sup,
    .__progress {
        display: block;
        position: relative;
    }
}

.block-info {
    --padding-top: #{180px / 16px * 1rem};
    --padding-bottom: var(--padding-xs);
    --padding-h: var(--padding-xxs);
    --font-size-title: var(--font-size-xlarge);

    @media (max-width: $smartphone) {
        --padding-top: #{80px / 16px * 1rem};
        --padding-bottom: var(--padding-small);
        --padding-h: var(--padding-xs);
        --font-size-title: var(--font-size-large);
    }
}

.block-info {
    @include font-sans();

    background: var(--white);
    color: var(--black);
    width: 100vw;

    .line-parent {
        overflow: hidden;
    }

    .wrapper {
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        min-height: 100vh;
        position: relative;
        width: 100%;
      }

    .lang-content {
        position: relative;
        width: 100%;
    }

    .lang-block {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        &:not(.--active) {
            opacity: 0;
            pointer-events: none;
        }
    }

    a,
    button {
        @include link-underline();
        color: currentColor;
    }

    h2 {
        @include font-sans-thin(1.05);
        font-size: var(--font-size-title);
    }

    .text {
        @include font-sans-thin(1.111);
        letter-spacing: initial;
        font-size: var(--font-size-mid);
    }

    .text-small {
        font-size: var(--font-size-base);
    }

    .label {
        display: block;
        margin-bottom: var(--padding-xxs);
    }

    .langs {
        @include font-sans(1.1);
        @include z-index($z-index-3D);

        display: flex;
        justify-content: flex-start;
        // font-weight: 100;

        button {
            padding: 0;

            &:not(:last-child) {

                margin-right: var(--padding-xxs);
            }

            &.--active { pointer-events: none; }
        }
    }

    ul {
        li {
            position: relative;

            .line-parent {
                padding-left: var(--padding-small);
            }

            .line-child::before {
                content: '·';
                position: absolute;
                margin-left: calc(-1 * var(--padding-xs));
            }
        }
    }

    .rrss {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: $smartphone) {
        a,
        button {
            background-size: 0;
            text-decoration: underline;

            &.--active {
                font-weight: bold;
                background-size: 0;
                text-decoration: none;
            }
        }

        h2 {
            letter-spacing: -.05rem;
            margin-bottom: var(--padding-m);
        }

        .langs {
            margin-bottom: var(--padding-sm);


            button:not(:last-child) {
                margin-right: var(--padding-small);
            }
        }

        .lang-content {
            height: rem(850);
            margin-bottom: var(--padding-s);
        }

        .content {
            > div:first-child {
                margin-bottom: var(--padding-m);
            }

            > div:last-child {
                margin-top: var(--padding-s);
            }
        }

        .footer {
            display: flex;
            flex-wrap: wrap;
            gap: var(--padding-small);
            padding: var(--padding-bottom) var(--padding-h);

            > * {
                min-width: calc(50% - var(--padding-small) / 2);
                width: calc(50% - var(--padding-small) / 2);
            }

            .address { order: 1; }
            .contact { order: 2; }
            .rrss { order: 3; }
            .credits { order: 4; }
            .copyright { order: 5; }
        }
    }

    @media (min-width: $smartphone) {
        h2 {
            > div:first-child { text-indent: rem(153); }
        }

        .scroller {
            max-height: 100vh;
        }

        .langs {
            margin-top: rem(45);
            margin-left: rem(10);
            position: absolute;
        }

        .lang-content {
            height: rem(465);
            position: relative;
            margin-bottom: rem(110);
        }

        .content {
            display: flex;
            margin-top: rem(75);

            > div {
                &:first-child {
                    width: 60%;
                    padding-right: var(--padding-s);

                    > * {
                        max-width: rem(710);
                    }
                }

                &:nth-child(2),
                &:last-child {
                    width: 20%;
                }
            }
        }

        .footer {
            bottom: var(--padding-bottom);
            align-items: flex-end;
            display: flex;
            padding: 0 var(--padding-h);
            position: absolute;
            width: 100%;

            .copyright {
                width: 25%;
            }

            .credits {
                margin-right: 10%;
                width: 30%;

                a {
                    background: none;
                    text-decoration: underline;
                }
            }

            .address {
                width: 20%;
            }

            .contact,
            .rrss {
                width: 10%;
            }

            .rrss {
                align-items: flex-end;
            }
        }
    }
}
